import React, { useRef } from 'react'
import useStyles from './styles';
import { Button } from '@mui/material'

import logo from '../../images/logo.svg';
import ReactToPrint from "react-to-print";


const AdmitCard = () => {
    const classes = useStyles();
    var componentRef = useRef();

    return (
        <div className={classes.mainContainer}>
            <div className={classes.container} ref={(el) => (componentRef = el)}>
                <div className={classes.box}>

                    <div className={classes.topBlock}>
                        <div><img className={classes.logo} src={logo} alt="icon" height="35px" /></div>

                        <div classname={classes.textBlock}  >
                            <h1 className={classes.toptext}>MATRICULA</h1>
                            <h4 className={classes.toptext}>ADMIT CARD</h4>
                        </div>

                        <div className={classes.helperBox}>
                            
                        </div>
                    </div>
                    <div className={classes.details}>
                        <h3 className={classes.detailText}>UNIQUE ID:</h3>
                        <h3 className={classes.detailText}>CANDIDATE'S NAME:</h3>
                        <h3 className={classes.detailText}>SCHOOL:</h3>
                        <h3 className={classes.detailText}>DATE OF BIRTH:</h3>
                        <h3 className={classes.detailText}>VENUE:</h3>
                    </div>
                    <p className={classes.pText}>Is permitted to appear at the Matricula Test Series 2022 in the subjects given below: </p>

                    <table className={classes.table}>
                        <tr className={classes.tableRow}>
                            <th className={classes.tableElement}>SUBJECT</th>
                            <th className={classes.tableElement}>DATE</th>
                            <th className={classes.tableElement}>TIME</th>
                        </tr>
                        <tr>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                            <td className={classes.tableElement}></td>
                        </tr>
                    </table>
                    <div className={classes.importantText}>
                        <p className={classes.pText}>Important Note:</p>
                        <ol className={classes.list}>
                            <li className={classes.listElement}>Mandatory to carry the admit card or the text message to the venue of the examination</li>

                            <li className={classes.listElement}>This card is issued by the Matricula and is required to be signed by the Matricula designated representative.</li>

                        </ol>
                    </div>
                    <div className={classes.signSuperBlock}>
                        <div className={classes.signBlock}>
                            <div className={classes.signature}></div>
                            <p>Full Signature <br />(of candidate)</p>
                        </div>
                        <div className={classes.signBlock}>
                            <div className={classes.signature}></div>
                            <p>Full Signature<br /> (controller of examination)</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* button to trigger printing of target component */}
            <ReactToPrint
                trigger={() => <Button color="primary" variant="contained" className={classes.submit}>Print</Button>}
                content={() => componentRef}
            />
        </div>

    )
}

export default AdmitCard