import { Paper } from '@mui/material'
import React from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

function SearchList({setSelectedStudent,setSelected,list}) {
  return (
    <Paper style={{maxHeight:'40rem',overflow:'auto'}}>
        {list.map((item,index) => {
            return (
                item &&
                <ListItem 
                style={item?.isResultAdded ? {backgroundColor:'green',color:'white'} : {}}
                onClick={()=>{
                    setSelected(true)
                    setSelectedStudent(item)
                  }} key={index} component='button'>
                    <ListItemText sx={{fontSize:'30px !important'}} primary={`${item.phoneNumber} / ${item.firstName} ${item.lastName} / ${item.studentClass} / ${item.schoolName} / ${item.board}`} />
                </ListItem>
            )
        })}
    </Paper>
  )
}

export default SearchList
