//import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";
//export default makeStyles((theme) => ({
  export default styled((theme) => ({
    navbar: {
        width: '100%',
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            width: '95%',
    
        },
    },
    logo: {
        padding: "2rem 7rem",
    },

    list: {
        display: "flex",
        flexDirection: "row",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        [theme.breakpoints.down('md')]: {
            display: "none"
        },
    

    },

    box: {
        padding: "4rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto"

    },
    textBlock: {
        textAlign: "center",
        margin: "auto",
        fontSize: "1.5rem",
        textDecoration: "none",
        color: "black",
        opacity: "0.8",
        
        textTransform: "uppercase",
        "&:hover":{
            opacity: 1,
        }
       
    },
    text: {
        padding: ".5rem 0rem",


    },
    button: {
        width: "100%",
        height: "80%",
        backgroundColor: "#185694",
      
       

    },
    dropdown:{
        border: "none",
        outline: "none",
        padding: "1.4rem 0",
        margin: 0,
        fontFamily: "inherit",
    },
    dropdownContent:{
        
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        boxShadow: '0 0 .2rem .1rem grey',
        background: "white",
        zIndex: 1,
     
    },
    droplink:{
        padding: "1.3rem",
        textAlign: "center",
        color: "black",
        fontSize: "1.5rem",
        textDecoration: "none",
      "&:hover":{
        background: "#f2f2f2",
      }
       
    }



}));