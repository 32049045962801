//import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";
//export default makeStyles((theme) => ({
  export default styled((theme) => ({


    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100rem",
        height: "60rem",
        margin: "auto",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            margin: "auto",
            width: "80%",
            height: "50rem"
        },

    },
    form: {

        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        height: "100%",
        textAlign: 'center',
        boxShadow: '0 0 .3rem .2rem #185694',
        marginTop: "15rem",
        marginBottom: "10rem",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: "10rem",
            marginBottom: "5rem",
        },
    },
    paper1: {
        width: "50%",
        height: "100%",
        textAlign: 'center',
        boxShadow: '0 0 .3rem .2rem #185694',
        marginTop: "15rem",
        marginBottom: "10rem",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },
    image: {
        width: "100%",
        height: "100%",

        objectFit: "cover",
    },
    group: {
        width: "100%",
        display: 'flex',
        paddingBottom: "2rem",
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: "30%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
    },
    label: {
        width: "50%",
        paddingRight: "1rem",
        fontSize: "2rem",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            width: "10rem",
            textAlign: "center",
            paddingBottom: "1.5rem",
            fontSize: "1.5rem"
        },

    },
    text: {
        marginBottom: "4rem",
        textAlign: "center",
        fontSize: "3rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem",
            marginTop: "2rem"
        },
    },
    submit: {
        width: "40%",
        margin: "1rem",
        padding: "1rem",
        borderRadius: "1.2rem",
        backgroundColor: "#185694",
        fontSize: "1.4rem"
    },
    box: {
        borderRadius: "2rem",
        outline: 'none !important',
        padding: "1.5rem",
        border: ".2rem solid black",
        fontSize: "1.6rem"
    }

}));