//import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";
//export default makeStyles((theme) => ({
  export default styled((theme) => ({
    container: {
        width: "100%",
        // margin: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "auto",
        [theme.breakpoints.down('sm')]:{
            margin: "auto",
        }     
    },
    header:{
        width: '50%',
        height: '5rem',
    },
    paper: {
        boxShadow: '0 0 .3rem .2rem #185694',
        width: "40%",
        margin: "2rem",
        padding: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]:{
            width: "60%",
           
        }
        
    },
    item: {
        width: "50%",
        margin: "1.4rem",
        padding: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            width: "80%"
        }
    },
    itemSelect: {
        width: "55%",
        margin: "1.5rem",
        padding: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            width: "100%"
        }
    },
    
    submit: {
        width: "40%",
        [theme.breakpoints.down('sm')]:{
            width: "100%"
        }
        
    },
    label:{
        fontSize: "2rem"
    },
    table: {
        borderCollapse: "collapse",
        // width: "50%",
        fontSize: "1.5rem",
        marginLeft: "50rem",
        [theme.breakpoints.down('sm')]:{
            width: "80%"
        }

    },
    tableElement: {
        height: "2rem",
        border: ".01rem solid black",
        padding: ".8rem",
        textAlign: "center"
    },

}));
