import { Button, Container, Input, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { CircularProgress, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import {useSelector, useDispatch} from 'react-redux'
import { addStudentMarks, getAllStudentDetails, getStudentDetails, getStudentMarks, sendResultSms } from '../../redux/actions/superAdminAction'
import SearchList from './SearchList'
import clsx from 'clsx'
import Swal from 'sweetalert2';
import useDebounce from '../../hooks/useDebounce'
import { Constants } from '../../utils/constants'

let fullMarks = Constants.constantMarks.fullMarks
let passMarks = Constants.constantMarks.passMarks

function AddMarks() {
    const [student, setStudent] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles()

    const [view,setView] = useState('Normal');

   
    
    const [cls,setCls] = useState('X');
    const [board,setBoard] = useState('WB BOARD');

    useEffect(() => {
        console.log('class',cls);
        if(cls === 'X'){
            fullMarks = 90
            passMarks = 25
        }
        if(cls === 'XII'){
            fullMarks = 80
            passMarks = 30
        }
    }, [cls])

    const [selected,setSelected] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [marks,setMarks] = useState([]);
    const [studentList,setStudentList] = useState([]);

    const {loading,error,data} = useSelector(state => state.getRegisterStudentDetails);
    const {updating,updatedMarks,updateError} = useSelector(state => state.studentMarks);
    const {marksData,loadingMarks} = useSelector(state => state.studentMarksDetails);
    const {sending,smsError,smsData} = useSelector(state => state.resultSms);

    const handleStudentSearch = async () => {
        setSelected(false);
        console.log('searching student ', student);
        await dispatch(getStudentDetails(student));
    }

    console.log(data);

    const [rowData,setRowData] = useState(null);

   let rowDataInit = selectedStudent && selectedStudent?.subjects.map((item,index) => {
            return {
                id:index,
                subject:item,
                marks: undefined
            }
        })


    useEffect(() => {
        if(selectedStudent !== null){
            console.log('rowData',rowData);
            dispatch(getStudentMarks(selectedStudent?._id))
        }
    },[selectedStudent,dispatch])

    useEffect(() => {
        if(data && data.success){
            setStudentList(data.studentInfo)
        }
    },[data])

    useDebounce(() => {
        if(student !== null && student !== undefined && student.trim().length > 0) handleStudentSearch();
    },300,[student])

    useEffect(() => {
        if(marksData && marksData.success){
            setRowData(marksData.studentInfo.marks)
            console.log('rowData',rowData);
            setMarks(marksData.studentInfo.marks)
        }
        else{
            setRowData(rowDataInit)
            setMarks(rowDataInit)
        }
    },[marksData])

    const setMarksForSubject = (subject,marks) => {
        setMarks(prev => prev.map((item,index) => {
            if(item.subject === subject){
                item.marks = parseInt(marks)  
            }
            return item
        }))
    }
    const setFullMarksForSubject = (subject,marks) => {
        setMarks(prev => prev.map((item,index) => {
            if(item.subject === subject){
                item.fullMarks = parseInt(marks)
            }
            return item
        }))
    }
    const setPassMarksForSubject = (subject,marks) => {
        setMarks(prev => prev.map((item,index) => {
            if(item.subject === subject){
                item.passMarks = parseInt(marks)
            }
            return item
        }))
    }
    
    useEffect(() => {
        if(!updating && updatedMarks && updatedMarks.success){
        Swal.fire({
            icon: 'success',
            title: 'Marks Updated Successfully',
          })
          data.studentInfo = data.studentInfo.map(item => {
                if(item?._id === selectedStudent?._id){
                    item.isResultAdded = true
                }
                return item
          })
          setStudentList(data.studentInfo)
        }
    },[updateError,updatedMarks,updating])

    useEffect(() => {
        if(!sending && smsData?.success){
        Swal.fire({
            icon: 'success',
            title: 'SMS Sent Successfully',
          })
          data.studentInfo = data.studentInfo.map(item => {
                if(item?.phoneNumber === smsData?.phoneNumber){
                    item.isResultSent = true
                }
                return item
          })
          setStudentList(data.studentInfo)
        }
    },[sending,smsError,smsData])

    useEffect(()=>{        
        if(view === 'List'){
            dispatch(getAllStudentDetails());
        }
    },[view])

    useEffect(() => {
        setView('List')
    },[])

    /**
     * @description This function will add marks for the selected student
     * Marks = marks [{subject:'Maths',marks:90,passMarks:25,fullMarks:90}]
     */
    const addMarks = async () => {
        await dispatch(addStudentMarks(selectedStudent._id,marks.map(item => {
            if(item.marks === null || item.marks === undefined || (typeof (item.marks) === 'number' && !(item.marks >= 0))){
                item.marks = 'Absent'
            }
            if(item.passMarks === undefined){
                item.passMarks = passMarks
            }
            if(item.fullMarks === undefined){
                item.fullMarks = fullMarks
            }
            console.log('item',item);
            return item
        }).filter(item => item.subject.trim().length > 0)))
        console.log('marks',marks);
    }

    const handleSms = async (phoneNumber) => {
        await dispatch(sendResultSms(phoneNumber))
    }

    return (
    <Container>
        <Stack direction={'column'} gap={2}>
            <Typography variant="h4" style={{textAlign:'center',margin:'20px 0'}}>Add Student Marks</Typography>
            <select style={{alignSelf:'flex-end'}} defaultValue={'List'} name='view' onChange={(e)=> setView(e.target.value)}>
                <option value={'List'}>List View</option>
                <option value={'Normal'}>Normal View</option>
            </select>
            <label htmlFor='class'>Select Class</label>
            <select defaultValue={'X'} value={cls} onChange={(e) => setCls(e.target.value)} name='class' style={{padding:'1rem'}}>
                <option value="X">Class X</option>
                <option value="XII">Class XII</option>
            </select>
            <label htmlFor='board'>Select Board</label>
            <select defaultValue={'WB BOARD'} value={board} onChange={(e) => setBoard(e.target.value)} name='board' style={{padding:'1rem'}}>
                <option value="WB BOARD">WB BOARD</option>
                <option value="ICSE">ICSE</option>
                <option value="CBSE">CBSE</option>
            </select>
            <Stack direction={'row'} gap={2}>
                <Input onFocus={() => setSelected(false)} value={student} onChange={(e)=>setStudent(e.target.value)} style={{width:'100%',fontSize:'1.5rem'}} type="text" placeholder="Enter Student UID/Phone number" />
                {/* <Button onClick={handleStudentSearch} variant="contained" color="primary"><Search/></Button> */}
            </Stack>
            {view === 'Normal' && 
            <>
            {loading && <CircularProgress/>}
            {data && data?.success && !selected && <SearchList setSelected={setSelected} setSelectedStudent={setSelectedStudent} list={data.studentInfo.filter(item => item.studentClass === cls && item.board === board)}/>}
            {selectedStudent && 
                <MarksForm classes={classes} handleSms={handleSms} selectedStudent={selectedStudent} loadingMarks={loadingMarks} rowData={rowData} setMarksForSubject={setMarksForSubject} setPassMarksForSubject={setPassMarksForSubject} setFullMarksForSubject={setFullMarksForSubject} handleMarks={addMarks}/>
            }
            </>}
            {view === 'List' &&
            <Stack direction={'row'} alignItems='flex-start'>
                {loading && <CircularProgress/>}
                <TableBody style={{flex: 1,zIndex:1,outline:'1px solid rgba(0,0,0,0.1)'}}>
                    <TableHeader classes={classes} data={['Name','Contact No.','Marks Added','','']}/>
                    {studentList.filter(item => item.studentClass === cls && item.board === board).map(item => 
                    <TableRow style={{background:item?.phoneNumber === selectedStudent?.phoneNumber ? 'rgba(100,150,255,0.5)' : item?.isResultAdded ? 'rgba(170,130,70,0.2)' : 'white'}}>
                        <TableCell style={{fontSize:'1.5rem',textTransform:'uppercase', borderLeft:item?.isResultAdded && '3px solid green'}}>{item.firstName} {item.lastName}</TableCell>
                        <TableCell style={{fontSize:'1.5rem',textAlign:'center'}}>{item?.phoneNumber}</TableCell>
                        <TableCell style={{fontSize:'1.5rem',textAlign:'center'}}>{item?.isResultAdded ? 'Yes' : 'No'}</TableCell>
                        <TableCell><Button onClick={() => {
                            setSelected(true)
                            setSelectedStudent(item)
                        }} variant="contained" color="primary">{item.isResultAdded ? 'Update' : 'Add'} Marks</Button></TableCell>
                        <TableCell>{item.isResultAdded && <Button onClick={()=>handleSms(item.phoneNumber)} variant="contained" color="secondary">{item.isResultSent ? 'Resend' : 'Send'} Sms</Button>}</TableCell>
                    </TableRow>
                    )}                
                </TableBody>
                {selectedStudent && 
                <MarksForm handleSms={handleSms} classes={classes} selectedStudent={selectedStudent} loadingMarks={loadingMarks} rowData={rowData} setMarksForSubject={setMarksForSubject} setPassMarksForSubject={setPassMarksForSubject} setFullMarksForSubject={setFullMarksForSubject} handleMarks={addMarks}/>
            }
            </Stack>
            }
            {error && <Typography variant="h6" style={{textAlign:'center',margin:'20px 0'}}>{error}</Typography>}
        </Stack>
    </Container>
  )
}

//Marks Form

function MarksForm ({classes,selectedStudent,loadingMarks,rowData,setMarksForSubject,setFullMarksForSubject,setPassMarksForSubject,handleMarks,handleSms}) {
    
    return (
        <Stack direction={'column'} alignItems='center' justifyContent={'space-between'} gap={2} position='sticky' top={'5rem'}>
            <Typography variant="h4">Student Name: {selectedStudent.firstName} {selectedStudent.lastName}</Typography>
            {loadingMarks ? 
            <Typography variant="h6" style={{textAlign:'center',margin:'20px 0'}}>Fetching Marks...</Typography>
            :
            <TableBody style={{flex: 1}}>
                <TableHeader classes={classes} data={['Subject','Full Marks','Pass Marks','Marks Obtained']}/>
                {rowData && rowData.filter(item => item.subject.trim().length > 0).map(data =><TableContent setMarksForSubject={setMarksForSubject} setFullMarksForSubject={setFullMarksForSubject} setPassMarksForSubject={setPassMarksForSubject} fullMarks={fullMarks} passMarks={passMarks} classes={classes} data={data}/>)}
            </TableBody>}
            <Stack direction={'row'} gap={1}>
                <Button onClick={handleMarks} style={{fontSize:'1.5rem'}} variant="contained" color="primary">{selectedStudent?.isResultAdded ? 'Update' : 'Add'} Marks</Button>
                <Button onClick={()=>handleSms(selectedStudent.phoneNumber)} style={{fontSize:'1.5rem'}} variant="contained" color="secondary">{selectedStudent?.isResultSent ? 'Resend' : 'Send'} SMS</Button>
            </Stack>
        </Stack>
    )
}

// Table Header
function TableHeader({classes,data}){
    return (
        <TableHead style={{position:'sticky',top:0, background:'white',zIndex:1}}>
            <TableRow>
                {data.map(d => <Header title={d} classes={classes}/>)}
            </TableRow>
        </TableHead>
    )
}

//Generic Table Header Component
function Header ({classes,title,textAlign,span,arrow}){
    return (
        <TableCell colSpan={span}>
            <Typography variant={'h5'} className={clsx(classes.bold,classes.text_center,textAlign)}>{title}</Typography>
        </TableCell>
    )
}

//Generic Table Cell Component
function Cell ({text,classes,span,textAlign}) {
    return (
        <TableCell colSpan={span}>
            <Typography variant={'h5'} className={clsx(classes.uppercase,classes.text_center,textAlign)}>{text}</Typography>
        </TableCell>
    )
}

//Table Content
function TableContent ({classes,data,fullMarks,passMarks,setMarksForSubject,setFullMarksForSubject,setPassMarksForSubject}) {
    console.log('marks data',data);
    return (
        <TableRow>
            <Header title={data.subject} classes={classes} textAlign={classes.text_left}/>
            {/* <Cell text={fullMarks} classes={classes}/>
            <Cell text={passMarks} classes={classes}/> */}
            <TableCell>
                <Input required={true} value={data?.fullMarks} defaultValue={fullMarks} onChange={(e) => setFullMarksForSubject(data.subject,e.target.value)} className={classes.text_center} type="number" placeholder="Enter Full Marks" style={{width:'100%',fontSize:'1.5rem'}}/>
            </TableCell>
            <TableCell>
                <Input required={true} value={data?.passMarks} defaultValue={passMarks} onChange={(e) => setPassMarksForSubject(data.subject,e.target.value)} className={classes.text_center} type="number" placeholder="Enter Pass Marks" style={{width:'100%',fontSize:'1.5rem'}}/>
            </TableCell>
            <TableCell>
                <Input  value={data?.marks || undefined} onChange={(e) => setMarksForSubject(data.subject,e.target.value)} className={classes.text_center} type="number" placeholder="Enter Marks Obtained" style={{width:'100%',fontSize:'1.5rem'}}/>
            </TableCell>
        </TableRow>
    )
}

export default AddMarks