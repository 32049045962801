//import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";
//export default makeStyles((theme) => ({
  export default styled((theme) => ({
    container: {
        width: "100vw",
        // margin: "2rem",
        display: "flex",
        flexWrap: "wrap",
        //justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            margin: "auto",
            flexDirection: "column",
            width: "100%",
        },

    },
    paper: {
        width: "10rem",
        height: "10rem",
        fontSize: "1.5rem",
        boxShadow: '0 0 .3rem .2rem #185694',
       // width: "10%",
        margin: "1rem",
        padding: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.down('xs')]: {
    
            width: "60%",
        },


    }

}));