import React, { useEffect, useState } from 'react'
import Select from "react-select";
import useStyles from "./addStyles";
import { Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux"
import { deleteDDE, getAllDDE } from '../../redux/actions/superAdminAction';
import Swal from 'sweetalert2';



import data from "./data.json";
const UpdateDde = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: getAllDDEData } = useSelector((state) => state.getAllDDE);
  const { data: deleteDDEData } = useSelector((state) => state.deleteDDE);

  const [district, setDistrict] = useState(null);

  const handleDistrict = (e) => {
    dispatch(getAllDDE(e.district.toLowerCase()));
    setDistrict(e);
  };

  useEffect(() => {
    dispatch(getAllDDE(district !== null ? district.district.toLowerCase() : ""));
  }, [deleteDDEData,dispatch,district])
  useEffect(() => {
    if(deleteDDEData){
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: "DDE deleted successfully",
      })
    }
  }, [deleteDDEData])
  


  // DELETE DDE
  const handleDelete = (DdeName) =>{
    dispatch(deleteDDE(district.district.toLowerCase(),DdeName));

  }

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <Typography variant="h4" className={classes.text}>
          UPDATE/DELETE DDE
        </Typography>
        <Select
          className={classes.dropdown}
          placeholder="Select District"
          value={district}
          options={data}
          onChange={(e) => handleDistrict(e)}
          getOptionLabel={(x) => x.district}
          getOptionValue={(x) => x.district}
        />

        {getAllDDEData && getAllDDEData.data.map((currentDDE, index) => {
          const count = index + 1;
          return (
            <div key={index}>
              <div className={classes.group}>
                <label className={classes.label} style={{ width: "50%" }}>{"DDE " + count + ":"}</label>
                    <input
                      className={classes.input}
                      disabled={true}
                      value={currentDDE.DDE}
                    />
                  <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{background: "red"}}
              onClick={()=>{handleDelete(currentDDE.DDE)}}
             

            >
              Delete
            </Button>
              </div>
            </div>
          );
        })}
      </div>

    </div>
  )
}

export default UpdateDde