import React, { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import useStyles from "./styles";
import Select from "react-select";
import data from "./data.json";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import { getBlocks, getSchools, addStudentInfo } from "../../redux/actions/adminAction";
import {
  subject_for_cbse_10th,
  subject_for_cbse_12th,
  subject_for_icse_10th,
  subject_for_icse_12th,
  subject_for_wbbse_10th,
  subject_for_wbbse_12th,
} from "./subjectData";
import { CircularProgress, Divider } from "@mui/material";

const DdeForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: blockData } = useSelector((state) => state.getBlocks);
  const { data: schoolData } = useSelector((state) => state.getSchools);
  const { data: studentInfoData, error, loading } = useSelector((state) => state.addStudentInfo);
  const { data: customState } = useSelector((state) => state.holdCustomState);


  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      })
    }
  }, [error])
  useEffect(() => {
    if (studentInfoData) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: "Data Added to our Database, Thank You",
      })
    }
  }, [studentInfoData, dispatch])

  const typeOptions = [
    {
      type: "Government",
    },
    {
      type: "Private",
    },
  ];
  const classOptions = [
    {
      class: "X",
      subject: [{ name: "English" }, { name: "Math" }],
    },
    {
      class: "XII",
      subject: [{ name: "English" }, { name: "Physics" }],
    },
  ];

  const [type, setType] = useState(null);
  const [classOp, setClassop] = useState(null);
  const [board, setBoard] = useState(null);
  const [district, setDistrict] = useState(null);
  const [block, setBlock] = useState(null);
  const [schoolName, setSchoolName] = useState();
  const [dde, setDde] = useState(null);

  const [subject1, setSubject1] = useState(null);
  const [subject2, setSubject2] = useState(null);
  const [subject3, setSubject3] = useState(null);
  const [subject4, setSubject4] = useState(null);
  const [subject5, setSubject5] = useState(null);
  const [subject6, setSubject6] = useState(null);
  const [subject7, setSubject7] = useState(null);

  const [edit, setEdit] = useState(false);
  const [isSelfRegister, setIsSelfRegister] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [alternativeNo, setAlternativeNo] = useState("");
  const [email, setEmail] = useState("");
  const [principalContact, setPrincipalContact] = useState("");
  const [checked, setChecked] = useState(false);
  const [count, setCount] = useState(0);
  const [subjectCount, setSubjectCount] = useState({
    subject1: 0,
    subject2: 0,
    subject3: 0,
    subject4: 0,
    subject5: 0,
    subject6: 0,
    subject7: 0,
  });

  const handleDistrict = (e) => {
    dispatch(getBlocks(e.district.toLowerCase()));
    setDistrict(e);
    // setBlockList(e.block);
    setBlock(null);
    // setDdm(e.ddm);
  };
  const handleBlock = (e) => {
    setBlock(e);
    dispatch(getSchools(district.district.toLowerCase(), e.block));
  };

  const handleClass = (e) => {
    setClassop(e);
  };
  const handleCheck = () => {
    setChecked(!checked)
    setEdit(!edit);
  }

  const registrationFeeCal = () => {
    let baseFee =
      type && type.type === "Government" && classOp && classOp.class === "X"
        ? 350
        : type &&
          type.type === "Government" &&
          classOp &&
          classOp.class === "XII"
          ? 250
          : type && type.type === "Private" && classOp && classOp.class === "X"
            ? 250
            : type && type.type === "Private" && classOp && classOp.class === "XII"
              ? 300
              : 0;

    if(type && type.type === "Government" && classOp && classOp.class === "X"){
      return baseFee
    }
    if(type && type.type === "Government" && classOp && classOp.class === "XII"){
      return baseFee
    }
    return baseFee * count;
  };

  const showSubject = () => {
    return board &&
      board.board === "ICSE" &&
      classOp &&
      classOp.class === "X"
      ? subject_for_icse_10th
      : board && board.board === "ICSE" && classOp && classOp.class === "XII"
        ? subject_for_icse_12th
        : board && board.board === "CBSE" && classOp && classOp.class === "X"
          ? subject_for_cbse_10th
          : board && board.board === "CBSE" && classOp && classOp.class === "XII"
            ? subject_for_cbse_12th
            : board && (board.board === "WBBSE"|| board.board === 'WBBME') && classOp && classOp.class === "X"
              ? subject_for_wbbse_10th
              : subject_for_wbbse_12th;
  };


  const handleSubmit = () => {
    if (!district || !block || !type || !classOp || !board || !schoolName || firstName === '' || lastName === '' || phoneNumber === '' || principalContact === '' || !subject1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Please fill all the fields",
      })
    }
    else {
      dispatch(addStudentInfo(customState.district.toLowerCase(), customState.block, (customState.DDM), (isSelfRegister ? customState.DDM : customState.DDE), customState.schoolType, customState.class, customState.board, customState.schoolName, firstName,
        lastName,
        phoneNumber,
        alternativeNo,
        email,
        customState.principalContact,
        subject1.name,
        subject2 ? subject2.name : "",
        subject3 ? subject3.name : "",
        subject4 ? subject4.name : "",
        subject5 ? subject5.name : "",
        subject6 ? subject6.name : "",
        subject7 ? subject7.name : "",registrationFeeCal()));
    }

  };

  // HANDLE CUSTOM STATE

  const handleCustomState = (e) => {
    if (!district || !block || !type || !classOp || !board || !schoolName || principalContact === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Please fill all the fields",
      })
    }
    else {


      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, do it!'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            {
              type:"HOLD_CUSTOM_STATE",
              payload:{
                  district:district.district.toLowerCase(),
                  block:block.block, 
                  DDM:(blockData && blockData.data.BDM), 
                  DDE:dde ? dde.DDE : (blockData && blockData.data.BDM),
                  schoolName:schoolName.school, 
                  schoolType:type.type, 
                  class:classOp.class, 
                  board:board.board,
                  principalContact:principalContact
              }
            }
          );
        }
      })
    }
  }


  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <div className={classes.form} autoComplete="off">
          <Typography variant="h4" className={classes.text}>
            ADD DETAILS
          </Typography>

          {
            !customState.district ?
            <>

          <Select
            className={classes.dropdown}
            placeholder="Select District"
            value={district}
            options={data}
            onChange={(e) => handleDistrict(e)}
            getOptionLabel={(x) => x.district}
            getOptionValue={(x) => x.district}
          />

          <Select
            className={classes.dropdown}
            placeholder="Select Block"
            value={block}
            options={blockData && blockData.data.Blocks}
            onChange={(e) => handleBlock(e)}
            getOptionLabel={(x) => x.block}
            getOptionValue={(x) => x.block}
          />

          <div className={classes.group}>
            <label className={classes.label}>DDM:</label>
            <input
              className={classes.input}
              value={blockData ? blockData.data.BDM.toUpperCase() : ""}
              disabled
            />
          </div>
          

          {
            !isSelfRegister ? 
            <Select
            className={classes.dropdown}
            placeholder="Select DDE"
            value={dde}
            options={blockData && blockData.data.DDE}
            onChange={(e) => setDde(e)}
            getOptionLabel={(x) => x.DDE}
            getOptionValue={(x) => x.DDE}
          />
            :
            <input onChange={(e) => setDde({...dde,dde:e.target.value.toLocaleUpperCase()})} value={dde !== null ? (blockData && blockData.data.BDM) : (blockData && blockData.data.BDM) } disabled={true} className={classes.editInput} />
          }


        <div style={{ fontSize: "1.5rem", }}>
            <label htmlFor="check" style={{ color: "grey" }}>Self Registration ? </label>

            <input type="checkbox" defaultChecked={checked} onChange={()=>{setIsSelfRegister(!isSelfRegister)}} name="check" style={{ marginRight: ".5rem" }} />
          </div>



          {edit ?
            <input onChange={(e) => setSchoolName({...schoolName,school:e.target.value.toLocaleUpperCase()})} value={schoolName && schoolName.school} placeholder="Enter School Name" className={classes.editInput} />

            :
            <Select
              className={classes.dropdown}
              placeholder="Select School Name"
              value={schoolName}
              options={schoolData && schoolData.data.schools}
              onChange={(e) => setSchoolName(e)}
              getOptionLabel={(x) => x.school}
              getOptionValue={(x) => x.school}
            />
          }

          <div style={{ fontSize: "1.5rem", }}>
            <label htmlFor="check" style={{ color: "grey" }}>Other School ? </label>

            <input type="checkbox" defaultChecked={checked} onChange={handleCheck} name="check" style={{ marginRight: ".5rem" }} />
          </div>  


          <Select
            className={classes.dropdown}
            placeholder="School Type"
            value={type}
            options={typeOptions}
            onChange={(e) => {
              setType(e);
            }}
            getOptionLabel={(x) => x.type}
            getOptionValue={(x) => x.type}
          />

          <Select
            className={classes.dropdown}
            placeholder="Select Class"
            value={classOp}
            options={classOptions}
            onChange={handleClass}
            getOptionLabel={(x) => x.class}
            getOptionValue={(x) => x.class}
          />

          <Select
            className={classes.dropdown}
            placeholder="Select Board"
            value={board}
            options={[{ board: "ICSE/ISC" }, { board: "CBSE" }, { board: "WB BOARD" }, { board: "WB MADRASA BOARD" }]}
            onChange={(e) => {
              setBoard(e);
            }}
            getOptionLabel={(x) => x.board}
            getOptionValue={(x) => x.board}
          ></Select>
            <div className={classes.group}>
              <label className={classes.label}>Principal Contact No.:</label>
              <input
                value={principalContact}
                onChange={(e) => setPrincipalContact(e.target.value)}
                className={classes.input} />
            </div>
            <Divider style={{marginBottom:'5rem'}}  />

                        <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleCustomState}
                      >
                        Confirm & Continue
                      </Button>
            </>

            
               :
              <>
                        <div className={classes.group}>
            <label className={classes.label}>First Name:</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={classes.input} required />
          </div>
          <div className={classes.group}>
            <label className={classes.label}>Last Name:</label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={classes.input} required />
          </div>
          <div className={classes.group}>
            <label className={classes.label}>Mobile No:</label>
            <input
              value={phoneNumber}
              onChange={(e) => setphoneNumber(e.target.value)}
              required className={classes.input} />
          </div>
          <div className={classes.group}>
            <label className={classes.label}>Alternative Mobile No:</label>
            <input
              value={alternativeNo}
              onChange={(e) => setAlternativeNo(e.target.value)}
              className={classes.input} />
          </div>
          <div className={classes.group}>
            <label className={classes.label}>Email:</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.input} />
          </div>
          <Select
            className={classes.dropdown}
            placeholder="Select Subject 1"
            value={subject1}
            options={showSubject()}
            onChange={(e) => {
              setSubject1(e);
              if (subjectCount.subject1 === 0) {
                setSubjectCount({ ...subjectCount, subject1: 1 });
                setCount(count + 1);
              }
            }}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <Select
            className={classes.dropdown}
            placeholder="Select Subject 2"
            value={subject2}
            options={showSubject()}
            onChange={(e) => {
              setSubject2(e);
              if (subjectCount.subject2 === 0) {
                setSubjectCount({ ...subjectCount, subject2: 1 });
                setCount(count + 1);
              }
            }}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <Select
            className={classes.dropdown}
            placeholder="Select Subject 3"
            value={subject3}
            options={showSubject()}
            onChange={(e) => {
              setSubject3(e);
              if (subjectCount.subject3 === 0) {
                setSubjectCount({ ...subjectCount, subject3: 1 });
                setCount(count + 1);
              }
            }}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <Select
            className={classes.dropdown}
            placeholder="Select Subject 4"
            value={subject4}
            options={showSubject()}
            onChange={(e) => {
              setSubject4(e);
              if (subjectCount.subject4 === 0) {
                setSubjectCount({ ...subjectCount, subject4: 1 });
                setCount(count + 1);
              }
            }}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <Select
            className={classes.dropdown}
            placeholder="Select Subject 5"
            value={subject5}
            options={showSubject()}
            onChange={(e) => {
              setSubject5(e);
              if (subjectCount.subject5 === 0) {
                setSubjectCount({ ...subjectCount, subject5: 1 });
                setCount(count + 1);
              }
            }}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <Select
            className={classes.dropdown}
            placeholder="Select Subject 6"
            value={subject6}
            options={showSubject()}
            onChange={(e) => {
              setSubject6(e);
              if (subjectCount.subject6 === 0) {
                setSubjectCount({ ...subjectCount, subject6: 1 });
                setCount(count + 1);
              }
            }}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <Select
            className={classes.dropdown}
            placeholder="Select Subject 7"
            value={subject7}
            options={showSubject()}
            onChange={(e) => {
              setSubject7(e);
              if (subjectCount.subject7 === 0) {
                setSubjectCount({ ...subjectCount, subject7: 1 });
                setCount(count + 1);
              }
            }}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <div className={classes.group}>
            <label className={classes.label}>Amount:</label>
            <input
              className={classes.input}
              value={registrationFeeCal()}
              readOnly
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress color="inherit" /> : "Submit"}
          </Button>
              </>
            }
        </div>
      </div>
    </div>
  );
};

export default DdeForm;
