import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Paper, TableCell, Typography } from '@mui/material'
import { Stack, Grid, Table, TableHead, TableBody, TableRow, Button } from '@mui/material'
import clsx from 'clsx'
import useStyles from './styles'
import { useReactToPrint } from 'react-to-print'
import ResultLogic from './Result.Logic.js'

import './result.css'

import Matricula from '../../images/Matricula_Logo.png'
import TestSeries from '../../images/Test Series Logo.jpg'
import Stamp from '../../images/matricula_stamp.png'
import { getStudentMarks } from '../../redux/actions/superAdminAction'

const {NumberToWords, gradingData, gradingRowData, calculateGrade, resultHeaders } = ResultLogic()

function Result() {
    const classes = useStyles()
    const resultRef= useRef()

    const dispatch = useDispatch()

    const handlePrint = useReactToPrint({
        content: () => resultRef.current,
    })


    const {userInfo} = useSelector((state) => state.userSignin)
    const {phoneNumber,subjects,firstName,lastName,studentClass, schoolName, _id} = userInfo?.data?.CheckStudent
    const {marksData,loadingMarks} = useSelector(state => state.studentMarksDetails);
    const fullMarks = 90
    const passMarks = 25

    useEffect(() => {
        dispatch(getStudentMarks(_id))
    },[])

    const [rowData,setRowData] = useState(null)
    const [total,setTotal] = useState(null)

    const getTotalMarks = () => {
        setTotal(rowData.reduce((acc,curr) => {
            console.log('acc: ',acc,'curr: ',curr);
            return acc + (typeof(curr.marks) === 'number' ? parseInt(curr.marks) : 0)
        },0))
    }

    useEffect(() => {
        if(marksData)
            setRowData(marksData?.studentInfo?.marks)
    },[marksData])

    useEffect(() => {
       if(rowData !== null && rowData?.length > 0) getTotalMarks()
    },[rowData])
    
    const [grade,setGrade] = useState(null)
    const [result,setResult] = useState(null)
    
    // const getResult = useCallback( (total) => {
    //     console.log('GET RESULT');
    //     if(total !== null){
    //         return ((total / rowData?.filter(item => item.subject.trim().length > 0).reduce((acc,curr) => {
    //             console.log(acc);
    //             return parseInt(acc.fullMarks) + parseInt(curr.fullMarks)
    //         }))*100).toFixed(2)
    //     }
    // },[total])
    
    const getResult = (total) => {
        console.log('GET RESULT');
        console.log('total:',total, rowData?.filter(item => item.subject.trim().length > 0));

        if(rowData?.filter(item => item.subject.trim().length > 0).length === 1)
            return ((total / rowData?.filter(item => item.subject.trim().length > 0)[0].fullMarks)*100).toFixed(2)

        return ((total / rowData?.filter(item => item.subject.trim().length > 0).reduce((acc,curr) => {
            console.log('acc:',acc, acc.fullMarks,curr,curr.fullMarks);
            if(typeof acc === 'object'){
                console.log('acc:',acc, acc.fullMarks,curr,curr.fullMarks);
                return acc.fullMarks + parseInt(curr.fullMarks)
            }
            else
                return parseInt(acc) + parseInt(curr.fullMarks)
        }))*100).toFixed(2)
    }
    
    useEffect(() => {
        console.log(total);
        if(total !== null){
            setGrade(calculateGrade(Math.round(getResult(total))))
            setResult(getResult(total))
        }
    },[total])

  return (
    <>
    <Container className='result-print' ref={resultRef}>
    <Paper className={classes.paper}>
        <div className={clsx(classes.result)}>
        <Stack direction={'column'} alignItems='center' gap={3}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <img src={Matricula} width='16%'/>
                <img src={TestSeries} width='30%'/>
                <img src={Matricula} width='16%'/>
            </Stack>
            <Typography className={classes.bold} variant='h3'>MARK SHEET OF MATRICULA TEST SERIES 2022 - 23</Typography>
            <Stack className={classes.flex_col}>
                <Typography className={clsx(classes.bold,classes.serif)} variant='h4'>Unique / Roll / Mobile No :</Typography>
                <Typography className={clsx(classes.bold,classes.border,classes.text_center)} variant='h4'>{phoneNumber}</Typography>
            </Stack>
            <Stack className={clsx(classes.flex_col,classes.self_start)}>
                <StudentInfo classes={classes} title={'name'} value={`${firstName} ${lastName}`}/>
                <StudentInfo classes={classes} title={'class'} value={studentClass}/>
                <StudentInfo classes={classes} title={'Appering from'} value={schoolName}/>
            </Stack>
            <Table className={classes.table}>
                <TableHeader classes={classes} data={resultHeaders}/>
                <TableBody>
                    {rowData && rowData.filter(item => item.subject.trim().length > 0).map(data =><TableContent fullMarks={fullMarks} passMarks={passMarks} classes={classes} data={data}/>)}
                    {/* Grand Total */}
                    <TableRow>
                        <Header span={'100%'} title={`Grand Total: ${total} (${NumberToWords(total)})`} classes={classes} textAlign={classes.text_left}/>
                    </TableRow>
                    <TableRow>
                        <Header span={3} title={`Result: ${result}%`} classes={classes} textAlign={classes.text_left}/>
                        <Header span={2} title={`Overall Grade: ${grade}`} classes={classes} textAlign={classes.text_left}/>
                    </TableRow>
                </TableBody>
            </Table>
        </Stack>

        <Stack direction={'column'}>
            <Stack direction='row' alignItems={'flex-start'} justifyContent='space-between' width={'100%'} spacing={5}>
                <Table className={classes.table}>
                    <TableHeader classes={classes} data={gradingData}/>
                    <TableBody>
                        {gradingRowData.map(data =><GradingContent classes={classes} data={data}/>)}
                    </TableBody>
                </Table>
                <Stack direction={'column'} alignItems='flex-end' gap={3}>
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <Cell text={'P'} classes={classes}/>
                            <Cell text={'For Successful Candidate'} classes={classes}/>
                        </TableRow>
                        <TableRow>
                            <Cell text={'F'} classes={classes}/>
                            <Cell text={'For Unsuccessful Candidate'} classes={classes}/>
                        </TableRow>
                    </TableBody>
                </Table>
                <Stack width={'70%'}>
                    <img width={'80%'} src={Stamp}/>
                    <Typography className={clsx(classes.bold,classes.border_top,classes.text_center,classes.serif)} variant='h4'>Full Signature<br></br>Controller of Examination</Typography>
                </Stack>
                </Stack>
            </Stack>
        </Stack>
        </div>
        <Typography className={clsx(classes.bold,classes.text_center,classes.border_top,classes.width_full)} variant='h5'>105, Upohar Town Center, New Garia, Kolkata - 700094. Helpline Number - (+91) 74397 09486 / 81004 82638
            <br></br>E-mail : hey@matricula.co.in Website : www.matricula.co.in</Typography>
    </Paper>
    </Container>
    <div style={{width:'100%',display:"flex",paddingTop:'2rem'}}>
        <Button style={{margin:'auto'}} sx={{fontSize:'2rem'}} onClick={handlePrint} variant='contained' color='primary'>Print Marksheet</Button>
    </div>
    </>
  )
}

function StudentInfo({classes,title,value}){
    return (
        <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            >
            <Typography className={clsx(classes.bold,classes.uppercase,classes.flex_20,classes.serif,classes.sm)} variant='h4'>{title}:</Typography>
            <Typography className={clsx(classes.border,classes.flex_1,classes.uppercase,classes.sm)} variant='h4'>{value}</Typography>
        </Grid>
    )
}


// Table Header
function TableHeader({classes,data}){
    return (
        <TableHead>
            <TableRow>
                {data.map(d => <Header title={d} classes={classes}/>)}
            </TableRow>
        </TableHead>
    )
}

//Table Content
function TableContent ({classes,data,fullMarks,passMarks}) {
    console.log(data);
    return (
        <TableRow>
            <Header title={data.subject} classes={classes} textAlign={classes.text_left}/>
            <Cell text={data.fullMarks} classes={classes}/>
            <Cell text={data.passMarks} classes={classes}/>
            <Cell text={data.marks || 'Absent'} classes={classes}/>
            <Cell text={calculateGrade(Math.round(data.marks*100/data.fullMarks))} classes={classes}/>
        </TableRow>
    )
}

function GradingContent ({classes,data}) {
    return (
        <TableRow>
            <Cell text={data.gradeScale} classes={classes} textAlign={classes.text_left}/>
            <Cell text={data.grade} classes={classes}/>
            <Cell text={data.performanceIndicator} classes={classes} textAlign={classes.text_left}/>
        </TableRow>
    )
}


//Generic Table Header Component
function Header ({classes,title,textAlign,span,arrow}){
    return (
        <TableCell colSpan={span}>
            <Typography variant={'h5'} className={clsx(classes.bold,classes.serif,classes.uppercase,classes.text_center,textAlign)}>{title}</Typography>
        </TableCell>
    )
}


//Generic Table Cell Component
function Cell ({text,classes,span,textAlign}) {
    return (
        <TableCell colSpan={span}>
            <Typography variant={'h5'} className={clsx(classes.uppercase,classes.text_center,textAlign)}>{text}</Typography>
        </TableCell>
    )
}





export default Result