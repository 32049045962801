//import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";
//export default makeStyles((theme) => ({
  export default styled((theme) => ({
    controlPanel:{
        width: "40rem",
        height: "20rem",
        backgroundColor: "#fff",
        position: 'absolute',
        // top: '50%',
        right: '10%',
        zIndex: 10000,
        borderRadius:"4rem",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    toggleControlPanel:{
        width: "20rem",
        height: "2rem",
        padding: "0.5rem",
        // backgroundColor: "#1a73e8",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',   
    },
    toggleBtn:{
        cursor: 'pointer',
        padding: "0.5rem",
        backgroundColor: "#1a73e8",
        display: 'flex',
        border: 'none',
        outline: 'none',
        color: '#fff',
        borderRadius: '1rem',
        justifyContent: 'center',
        alignItems: 'center',   
    },
    dropdown: {
        width: "100%",
        // marginLeft:'10rem',
        height: "50px",
        zIndex: 1000000,
        cursor: "pointer",
        fontSize: "1.6rem",
        outline: "none",
        padding: "1rem",
        margin: "auto",
        textAlign: "center",
        background: "#FFF",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            width: "100%",

        },
    },
    item: {
        width: "50%",
        margin: "1.4rem",
        padding: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            width: "80%"
        },
    },
    trackBtn: {
        width: "25%",
        borderRadius: "1rem",
        margin: "auto 0",
        padding: "1.5rem",
        border:'none',
        outline:'none',
        backgroundColor: "gray",
        cursor: "pointer",
        fontSize: "1.6rem",
        textAlign: "center",
        position: "relative",
        
    },  
    display:{
        display: "none",
    }

}));