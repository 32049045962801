//import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";
//export default makeStyles((theme) => ({
  export default styled((theme) => ({
    container: {
        width: "100%",
        // margin: "2rem",
        display: "flex",
        flexDirection: "row",
        //flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]:{
            margin: "auto",
        }     
    },
    header:{
        width: '50%',
        height: '5rem',
    },
    paper: {
        boxShadow: '0 0 .3rem .2rem #185694',
        width: "40%",
        margin: "2rem",
        padding: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]:{
            width: "60%",
           
        }
        
    },
    item: {
        width: "50%",
        margin: "1.4rem",
        padding: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            width: "80%"
        }
    },
    itemSelect: {
        width: "55%",
        margin: "1.5rem",
        padding: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            width: "100%"
        }
    },
    
    submit: {
        width: "40%",
        [theme.breakpoints.down('sm')]:{
            width: "100%"
        }
        
    },
    label:{
        fontSize: "2rem"
    },
    table: {
        borderCollapse: "collapse",
        width: "50%",
        fontSize: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            width: "80%"
        }

    },
    tableElement: {
        height: "2rem",
        border: ".01rem solid black",
        padding: ".8rem",
        textAlign: "center"
    },

    bold: {
        fontWeight: "bold"
    },

    text_center: {
        textAlign: "center"
    },

    text_left: {
        textAlign: "left"
    },

    flex_1: {
        flex: 1
    },

    // dropdown: {
    //     width: "50%",
    //     cursor: "pointer",
    //     fontSize: "1.6rem",
    //     outline: "none",
    //     padding: "1rem",
    //     margin: "auto",
    //     textAlign: "center",
    //     background: "transparent",

    //     [theme.breakpoints.down('sm')]: {
    //         width: "100%",

    //     },
    // },

    editInput:{
        margin: "1rem",
        width: "50%",
        padding: "1rem",
        borderRadius: ".5rem",
        outline: "none",
        border: "1px solid lightgray",
        textAlign: "center",
        textTransform:"uppercase",
        '&::placeholder': {
            textAlign: "center",
            fontSize: "1.4rem"

        },
        [theme.breakpoints.down('sm')]: {
            width: "80%",

        },
    },
    dropdown: {
        width: "50%",
        cursor: "pointer",
        fontSize: "1.6rem",
        outline: "none",
        padding: "1rem",
        margin: "auto",
        textAlign: "center",
        background: "transparent",

        [theme.breakpoints.down('sm')]: {
            width: "100%",

        },
    },

}));
